import { createGlobalStyle } from 'styled-components';

export const RetroTheme = createGlobalStyle`
  body {
    background-color: #0f0f0f;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    text-shadow: 0px 0px 10px #00ff00;
  }

  input, button {
    background-color: #0f0f0f;
    color: #00ff00;
    border: 1px solid #00ff00;
    padding: 10px;
    margin: 5px;
  }

  button {
    cursor: pointer;
  }
`;
