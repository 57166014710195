import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const CRTContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0f0f0f;
  color: #00ff00;
  font-family: 'Courier New', Courier, monospace;
  text-shadow: 0px 0px 10px #00ff00;
`;

const CRTTitle = styled.h1`
  font-size: 3em;
  animation: ${keyframes`
    0% { opacity: 0.1; }
    50% { opacity: 1; }
    100% { opacity: 0.1; }
  `} 2s infinite;
`;

const CRTDescription = styled.p`
  font-size: 1.5em;
  margin: 20px;
  text-align: center;
  max-width: 800px;
`;

const CRTButton = styled(Link)`
  padding: 20px 40px;
  margin-top: 20px;
  font-size: 1.2em;
  color: #00ff00;
  background-color: #0f0f0f;
  border: 2px solid #00ff00;
  text-decoration: none;
  cursor: pointer;
  animation: ${keyframes`
    0% { background-color: #0f0f0f; }
    50% { background-color: #00ff00; color: #0f0f0f; }
    100% { background-color: #0f0f0f; }
  `} 1.5s infinite;

  &:hover {
    background-color: #00ff00;
    color: #0f0f0f;
  }
`;

const HomePage = () => {
  return (
    <CRTContainer>
      <CRTTitle>TTS Reader</CRTTitle>
      <CRTDescription>
        Welcome to the TTS Reader! This tool allows you to upload various types of documents including PDFs, EPUBs, images, and even paste links to web pages. You can also record or upload an audio clip to clone a voice. Enjoy narrated descriptions of your documents with options to include images, charts, and footnotes.
      </CRTDescription>
      <CRTButton to="/upload">Get Started</CRTButton>
    </CRTContainer>
  );
};

export default HomePage;
