import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { FaUpload, FaMicrophone, FaLink, FaPlay, FaStop, FaTrash } from 'react-icons/fa';
import axios from 'axios';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #00ff00;
  margin: 20px;
  width: 90%;
  max-width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Description = styled.p`
  font-size: 1.2em;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
`;

const Button = styled.button`
  padding: 10px;
  margin: 10px;
  flex: 1;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border: 1px solid #00ff00;
  cursor: pointer;
`;

const URLInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const FileName = styled.p`
  margin: 0;
  padding: 0 5px;
  color: #00ff00;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 70%;
`;

const AudioVisualizer = styled.div`
  width: 100%;
  height: 100px;
  background: #0f0f0f;
  border: 1px solid #00ff00;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00ff00;
`;

const LoadingAnimation = keyframes`
  0% { opacity: 0.1; }
  50% { opacity: 1; }
  100% { opacity: 0.1; }
`;

const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0f0f0f;
  color: #00ff00;
  z-index: 1000;

  & > p {
    animation: ${LoadingAnimation} 1s infinite;
  }
`;

const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const ImagePreview = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  border: 1px solid #00ff00;

  & img {
    width: 100%;
    height: 100%;
  }

  & button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    color: #00ff00;
    cursor: pointer;
  }
`;

const NarratorDescriptionInput = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px;
  border: 1px solid #00ff00;
  background: #0f0f0f;
  color: #00ff00;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1em;
`;

const Counter = styled.p`
  color: #00ff00;
`;

const UploadForm = () => {
  const [files, setFiles] = useState([]);
  const [url, setUrl] = useState('');
  const [readingOptions, setReadingOptions] = useState({
    includeImages: false,
    includeFootnotes: false,
  });
  const [fileError, setFileError] = useState('');
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    let valid = true;

    if (selectedFiles.every(file => file.type === 'application/pdf')) {
      valid = true;
    } else if (selectedFiles.every(file => ['image/jpeg', 'image/png'].includes(file.type))) {
      valid = true;
    } else {
      valid = false;
    }

    if (!valid) {
      setFileError('Please upload valid PDF or image files (.jpg or .png).');
      setFiles([]);
      return;
    }

    setFileError('');
    setFiles(selectedFiles);
    setUrl('');  // Clear URL if files are selected
  };

  const handleURLChange = (event) => {
    setUrl(event.target.value);
    setFiles([]);  // Clear files if URL is entered
  };

  const handleOptionChange = (event) => {
    const { name, checked } = event.target;
    setReadingOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (files.length > 0 && url) {
      setFileError('Please upload either files or paste a URL, not both.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    formData.append('narrate_figures', readingOptions.includeImages);
    formData.append('include_footnotes', readingOptions.includeFootnotes);
    if (url) formData.append('url', url);

    try {
      const response = await axios.post('https://us-central1-tts-app-c0ccc.cloudfunctions.net/process_document', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setLoading(false);
      const audioBase64 = response.data.audio_data;
      const audioBlob = new Blob([Uint8Array.from(atob(audioBase64), c => c.charCodeAt(0))], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);
      navigate('/playback', { state: { audioUrl } });
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <>
      {loading && (
        <LoadingScreen>
          <p>Loading...</p>
        </LoadingScreen>
      )}
      <FormContainer>
        <Description>
          Welcome to the TTS Reader. Upload a PDF (.pdf), images (.jpg/.png), or paste a webpage URL to have it read aloud by our TTS engine.
        </Description>
        <form onSubmit={handleSubmit}>
          <StepContainer>
            <Description>Upload your file:</Description>
            <UploadLabel htmlFor="file-upload">
              <FaUpload /> Upload File
            </UploadLabel>
            <FileInput
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              multiple
              accept=".pdf, image/jpeg, image/png"
            />
            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
            {files.length > 0 && (
              <ImageList>
                {files.map((file, index) => (
                  <ImagePreview key={index}>
                    <FileName>{file.name}</FileName>
                    <button onClick={() => handleRemoveFile(index)}><FaTrash /></button>
                  </ImagePreview>
                ))}
              </ImageList>
            )}
            <Description>Or paste a URL:</Description>
            <URLInput type="text" value={url} onChange={handleURLChange} placeholder="Paste URL here" />
            <OptionContainer>
              <Option>
                <input
                  type="checkbox"
                  name="includeImages"
                  checked={readingOptions.includeImages}
                  onChange={handleOptionChange}
                />
                <label>Include narrated descriptions of images, charts, or other graphic figures</label>
              </Option>
              <Option>
                <input
                  type="checkbox"
                  name="includeFootnotes"
                  checked={readingOptions.includeFootnotes}
                  onChange={handleOptionChange}
                />
                <label>Include narration of footnotes</label>
              </Option>
            </OptionContainer>
            <ButtonGroup>
              <Button type="submit">Submit</Button>
            </ButtonGroup>
          </StepContainer>
        </form>
        {audioUrl && (
          <div>
            <audio controls>
              <source src={audioUrl} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
            <a href={audioUrl} download="narration.mp3">Download Audio</a>
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default UploadForm;
