import React, { useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaDownload, FaPlay, FaPause, FaForward, FaBackward } from 'react-icons/fa';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe("pk_live_51PBOfBCcje3A5NcbCwvb8ldqujw7bN1As1RrD0AugasUgtWewJMvr9rE2mKuSt8xGC3D942ydsDjAv6bqVJWglxN00PuL9Gxny");

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0f0f0f;
  color: #00ff00;
  font-family: 'Courier New', Courier, monospace;
  text-shadow: 0px 0px 10px #00ff00;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
`;

const AudioControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ControlButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;

  & > button {
    background: transparent;
    border: none;
    color: #00ff00;
    cursor: pointer;
    margin: 0 10px;
    font-size: 1.5em;
  }
`;

const Scrubber = styled.input`
  width: 80%;
  margin: 20px 0;
`;

const SpeedControl = styled.input`
  width: 200px;
  margin: 20px 0;
`;

const DownloadButton = styled.a`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1em;
  color: #00ff00;
  background-color: #0f0f0f;
  border: 2px solid #00ff00;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: #00ff00;
    color: #0f0f0f;
  }
`;

const PaymentButton = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1em;
  color: #00ff00;
  background-color: #0f0f0f;
  border: 2px solid #00ff00;
  cursor: pointer;
  &:hover {
    background-color: #00ff00;
    color: #0f0f0f;
  }
`;

const PlaybackPage = () => {
  const location = useLocation();
  const { audioUrl } = location.state;
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showCheckout, setShowCheckout] = useState(false);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleScrubberChange = (event) => {
    audioRef.current.currentTime = event.target.value;
  };

  const handlePlaybackRateChange = (event) => {
    const newPlaybackRate = event.target.value;
    setPlaybackRate(newPlaybackRate);
    audioRef.current.playbackRate = newPlaybackRate;
  };

  const skip = (amount) => {
    audioRef.current.currentTime += amount;
  };

  const fetchClientSecret = useCallback(() => {
    return fetch("https://us-central1-tts-app-c0ccc.cloudfunctions.net/create_checkout_session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({}) // Add any necessary data here
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  const handlePayment = () => {
    setShowCheckout(true);
  };

  return (
    <Container>
      <Title>Playback and Download Your TTS Audio</Title>
      <AudioControls>
        <audio
          ref={audioRef}
          src={audioUrl}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
        />
        <ControlButtons>
          <button onClick={() => skip(-30)}>
            <FaBackward /> 30s
          </button>
          <button onClick={togglePlayPause}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button onClick={() => skip(30)}>
            <FaForward /> 30s
          </button>
        </ControlButtons>
        <Scrubber
          type="range"
          min="0"
          max={duration}
          value={currentTime}
          onChange={handleScrubberChange}
        />
        <SpeedControl
          type="range"
          min="0.8"
          max="6"
          step="0.1"
          value={playbackRate}
          onChange={handlePlaybackRateChange}
        />
        <span>Playback Speed: {playbackRate}x</span>
        <DownloadButton href={audioUrl} download="tts-audio.wav">
          <FaDownload /> Download Audio
        </DownloadButton>
        <PaymentButton onClick={handlePayment}>
          Buy Now
        </PaymentButton>
        {showCheckout && (
          <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        )}
      </AudioControls>
    </Container>
  );
};

export default PlaybackPage;
